
import { ref } from "vue";
import { useRouter } from "vue-router";
import question from "@/views/QuestionView.vue";
import correctTwo from "@/views/CorrectTwoView.vue";
import correctThree from "@/views/CorrectThreeView.vue";
import correctReason from "@/views/CorrectReasonView.vue";
import diag from "@/views/DiagView.vue";
import { showDialog } from "vant";
// import toButton from "@/components/ToButton.vue DiagView.vue";
export default {
  components: { question, correctTwo, correctThree, diag, correctReason },
  data() {
    return {
      show: false,
      postaimqus: 2,
      title: "传递的是一个值",
      flag: "question",
      medica: "medica",
      radioVal: 0,
      correct: 1,
      num: 0,
    };
  },
  setup() {
    //router是全局路由对象，route= userRoute()是当前路由对象
    let router = useRouter();
    const checked = ref("");
    let name = "张三";
    let age = "18";
    let btnAim = "/medica";

    return { checked, age, btnAim };
  },
  methods: {
    showPopup() {
      this.show = true; //弹出问题弹窗
      console.log("倒数第二8888个问题弹出!");
      // this.$router.push({
      //   path: "/cluegogo",
      //   // query: { postaimqus: "4", correct: "two" },
      // });
    },
    diag(param) {
      //接受返回答案错误正确
      this.postaimqus = 1;
      this.flag = "question"; //弹出最后一个问题
    },
    correctTwo(param) {
      //接受返回答案错误正确
      this.postaimqus = 3;
      this.flag = "diag"; //弹出最后一个问题
    },
    updateCorrectThree(param) {
      //接受返回答案错误正确
      this.postaimqus = 3;
      this.flag = "question"; //弹出最后一个问题
    },
    correctReason(param) {
      //接受返回答案错误正确
      // this.postaimqus = 3;
      // this.flag = "question"; //弹出最后一个问题
      this.$router.push({
        path: "/report",
        // query: { postaimqus: "4", correct: "two" },
      });
    },
    updateChanged(param) {
      //点击问题的返回函数

      this.postaimqus = param[0];
      this.radioVal = param[1];

      if (this.postaimqus == 2) {
        if (this.radioVal == 0) {
          //处理问题没有选中

          console.log("test 走这里了");
          showDialog({
            title: "提示",
            message: "您还没有做出选择哟。",
          }).then(() => {
            // on close
          });
        } else {
          //第三道问题选中，如果选项是第四个，显示正确
          if (this.radioVal == 4) {
            this.flag = "correctTwo"; //显示正确
            this.correct = 1;
            this.postaimqus = 2;
          } else {
            //第三道问题选中，如果选项不是第四个，显示错误
            this.postaimqus = 2;
            this.flag = "correctTwo"; //显示错误
            this.correct = "0";
          }
        }
      }

      if (this.postaimqus == 1) {
        if (this.radioVal == 0) {
          //处理问题没有选中
          showDialog({
            title: "提示",
            message: "您还没有做出选择哟。",
          }).then(() => {
            // on close
          });
        } else {
          //第三道问题选中，如果选项是第四个，显示正确
          if (this.radioVal == 3) {
            this.flag = "correctReason"; //显示正确
            this.correct = 1;
            this.postaimqus = 1;
          } else {
            //第三道问题选中，如果选项不是第四个，显示错误
            this.postaimqus = 1;
            this.flag = "correctReason"; //显示错误
            this.correct = "0";
          }
        }
      }

      if (this.postaimqus == 3) {
        if (this.radioVal == 0) {
          showDialog({
            title: "提示",
            message: "您还没有做出选择哟。",
          }).then(() => {
            // on close
          });
        } else {
          //第四道问题选中，如果选项是第四个，显示正确
          if (this.radioVal == 4) {
            this.flag = "correctThree";
            this.correct = 1;
            this.postaimqus = 3;
          } else {
            //第四道问题选中，如果选项是第四个，显示错误
            this.postaimqus = 3;
            this.flag = "correctThree";
            this.correct = 0;
          }
        }
      } else {
        console.log("123");
      }
    },
  },
};
