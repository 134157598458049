
import { ref } from "vue";
import store from "@/store";
import { useRouter } from "vue-router";
export default {
  data() {
    return {
      radio: "1",
      radioVal: 1,
      questions: store.state.questions,
    };
  },
  props: ["postaimqus", "correct", "aimQus"],
  setup(props, any) {
    //router是全局路由对象，route= userRoute()是当前路由对象
    let router = useRouter();
    const checked = ref("");
    let name = "张三";
    let age = "18";

    return { checked, age };
  },
  methods: {
    // jump() {},
    nexStep() {
      //const aimQus = this.$route.query.aimQus;
      // console.log("aimQus10101010", aimQus);

      this.$router.push({
        path: "/report",
        // query: { postaimqus: "1", correct: "two" },
      });
      console.log("第几道题:twotwo" + this.aimQus);
      console.log("你的选择:" + this.radioVal);
      this.$emit("onChangedTwo", [this.aimQus, this.radioVal]);
    },
  },
};
