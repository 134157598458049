
import { ref } from "vue";
import { useRouter } from "vue-router";
import { reactive } from "vue";
import medical from "@/views/MedicalView.vue";
// import CarouselTwo from "@/components/CarouselTwo.vue";
import toNav from "@/components/ToNav.vue";
export default {
  components: { medical, toNav },
  data() {
    return {
      show: false,
      postaimqus: 0,
      title: "传递的是一个值",
      flag: true,
      medica: "medica",
    };
  },
  setup() {
    //router是全局路由对象，route= userRoute()是当前路由对象
    const imgs = reactive([
      {
        id: 0,
        url: " /static/img/leftimg.jpg",
        text: "感染",
        textOne: "中国儿童特应性皮炎诊疗共识指出感染是重要的加重诱发因素",
        textTwo: "是最重要的加重诱发因素",
      },
      {
        id: 1,
        url: " /static/img/rightimg.jpg",
        text: "黄金色葡萄球菌",
        textOne: "AD的主要合并症为感染，其中",
        textTwo: "定值为最常见的皮肤感染",
      },
    ]);
    let router = useRouter();
    const checked = ref("");
    let name = "张三";
    let age = "18";
    let btnAim = "/medica";

    return { checked, age, btnAim, imgs };
  },
  methods: {
    showPopup() {
      this.$router.push({
        path: "/summary",
        // query: { postaimqus: "4", correct: "two" },
      });
    },
    updateChanged(param) {
      console.log(param[0]); //获取param1的值
      console.log(param[1]); //获取param2的值
      this.aimQus = param[0];
      this.radioVal = param[1];
      switch (this.postaimqus) {
        case (this.postaimqus = 0):
          //这里是值对应的处理
          if (this.radioVal == 1) {
            this.postaimqus = 1;
            console.log("第一道题答对了，显示正确内容继续");
          } else {
            console.log("第一道题答错了，显示正确内容继续");
          }
          break;
        case (this.postaimqus = 1):
          //这里是值对应的处理
          if (this.radioVal == 4) {
            this.postaimqus = 2;
            console.log("第二道题答对了，显示正确内容继续");
          } else {
            console.log("第二道题答错了，显示正确内容继续");
          }
          break;
        case (this.postaimqus = 2):
          //这里是值对应的处理
          if (this.radioVal == 3) {
            this.postaimqus = 3;
            console.log("第三道题答对了，显示正确内容继续");
          } else {
            console.log("第三道题答错了，显示正确内容继续");
          }
          break;
        case (this.postaimqus = 3):
          //这里是值对应的处理
          if (this.radioVal == 1) {
            this.postaimqus = 4;
            console.log("第四道题答对了，显示正确内容继续");
          } else {
            console.log("第四道题答错了，显示正确内容继续");
          }
          break;
        case (this.postaimqus = 4):
          //这里是值对应的处理
          if (this.radioVal == 3) {
            console.log("第五道题答对了，显示正确内容继续");
          } else {
            console.log("第五道题答错了，显示正确内容继续");
          }
          break;

        default:
          //这里是没有找到对应的值处理
          break;
      }
    },
  },
};
