import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { id: "correct" }
const _hoisted_2 = { class: "expr" }
const _hoisted_3 = {
  key: 0,
  class: "lineOne"
}
const _hoisted_4 = {
  key: 1,
  class: "lineOne"
}
const _hoisted_5 = {
  key: 2,
  class: "lineTwo"
}
const _hoisted_6 = {
  key: 3,
  class: "lineTwo"
}
const _hoisted_7 = {
  key: 0,
  class: "answer"
}
const _hoisted_8 = {
  key: 1,
  class: "answer"
}
const _hoisted_9 = {
  key: 2,
  class: "answer"
}
const _hoisted_10 = {
  key: 3,
  class: "answer"
}
const _hoisted_11 = { class: "bxs" }
const _hoisted_12 = { class: "ip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_image = _resolveComponent("van-image")!
  const _component_van_radio_group = _resolveComponent("van-radio-group")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        ($props.correct == 1)
          ? (_openBlock(), _createBlock(_component_van_image, {
              key: 0,
              width: "2rem",
              height: "2rem",
              src: require('../assets/smile.png')
            }, null, 8, ["src"]))
          : _createCommentVNode("", true),
        ($props.correct == 0)
          ? (_openBlock(), _createBlock(_component_van_image, {
              key: 1,
              width: "2rem",
              height: "2rem",
              src: require('../assets/cry.png')
            }, null, 8, ["src"]))
          : _createCommentVNode("", true)
      ]),
      ($props.correct == 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, "太棒了"))
        : _createCommentVNode("", true),
      ($props.correct == 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, "判断错误"))
        : _createCommentVNode("", true),
      ($props.correct == 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, "判断正确"))
        : _createCommentVNode("", true),
      ($props.correct == 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, "要继续加油哦"))
        : _createCommentVNode("", true),
      _createVNode(_component_van_radio_group, {
        modelValue: $data.radioVal,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.radioVal) = $event))
      }, {
        default: _withCtx(() => [
          ($props.postaimqus == 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, " 答案解密：" + _toDisplayString($data.questions[$props.postaimqus].radioList[0].title), 1))
            : _createCommentVNode("", true),
          ($props.postaimqus == 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, " 答案解密：" + _toDisplayString($data.questions[$props.postaimqus].radioList[2].title), 1))
            : _createCommentVNode("", true),
          ($props.postaimqus == 2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, " 答案解密：" + _toDisplayString($data.questions[$props.postaimqus].radioList[3].title), 1))
            : _createCommentVNode("", true),
          ($props.postaimqus == 3)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, " 答案解密：" + _toDisplayString($data.questions[$props.postaimqus].radioList[3].title), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue"]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_van_image, {
          width: "5rem",
          height: "6.4267rem",
          src: require('../assets/bks.png')
        }, null, 8, ["src"])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createVNode(_component_van_image, {
        onClick: $options.nexStep,
        width: "2.5733rem",
        height: "2.5733rem",
        src: require('../assets/jixu.png')
      }, null, 8, ["onClick", "src"]),
      _createVNode(_component_van_image, {
        class: "finger",
        width: "1.28rem",
        height: "1.28rem",
        src: require('../assets/dong.gif')
      }, null, 8, ["src"])
    ])
  ], 64))
}