
// @ is an alias to /src
// import Carousel from "@/components/Carousel.vue";
import { reactive } from "vue";
export default {
  name: "HomeVue",
  components: {},
  methods: {
    // jump() {},
    nexStep() {
      const aimQus = this.$route.query.aimQus;
      console.log("aimQus9999999", aimQus);
      this.$router.push({
        path: "/clue",
        // query: { postaimqus: "1", correct: "two" },
      });

      console.log("第几道题:" + this.postaimqus);
      console.log("你的选择:" + this.radioVal);
      this.$emit("onChanged", [this.aimQus, this.radioVal]);
    },
  },
  setup() {
    const imgs = reactive([
      {
        id: 0,
        url: " /static/img/fenxi1.png",
        text: "感染",
        textOne: "中国儿童特应性皮炎诊疗共识指出",
        textTwo: "是重要的加重诱发因素",
      },
      {
        id: 1,
        url: " /static/img/fenxi2.png",
        text: "感染",
        textOne: "中国儿童特应性皮炎诊疗共识指出",
        textTwo: "是重要的加重诱发因素",
      },
      {
        id: 2,
        url: " /static/img/fenxi3.png",
        text: "感染",
        textOne: "中国儿童特应性皮炎诊疗共识指出",
        textTwo: "是重要的加重诱发因素",
      },
      {
        id: 3,
        url: " /static/img/fenxi4.png",
        text: "金黄色葡萄球菌",
        textOne: "AD的主要合并症为感染，其中",
        textTwo: "定植为最常见的皮肤感染",
      },
      {
        id: 4,
        url: " /static/img/fenxi5.png",
        text: "金黄色葡萄球菌",
        textOne: "AD的主要合并症为感染，其中",
        textTwo: "定植为最常见的皮肤感染",
      },
      {
        id: 5,
        url: " /static/img/fenxi6.png",
        text: "金黄色葡萄球菌",
        textOne: "AD的主要合并症为感染，其中",
        textTwo: "定植为最常见的皮肤感染",
      },
    ]);
    return {
      imgs,
    };
  },
  // mounted() {},
};
