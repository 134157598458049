import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-246a9a46"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "logo" }
const _hoisted_2 = { class: "pfk" }
const _hoisted_3 = { class: "ip" }
const _hoisted_4 = {
  style: {"width":"4rem","margin":"0 auto"},
  class: "home"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_image = _resolveComponent("van-image")!
  const _component_toButton = _resolveComponent("toButton")!
  const _component_medical = _resolveComponent("medical")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_van_image, {
        width: "2.1333rem",
        height: ".6667rem",
        src: require('../assets/logo.png')
      }, null, 8, ["src"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_van_image, {
        width: "8.3067rem",
        height: "8.3067rem",
        src: require('../assets/pfk.png')
      }, null, 8, ["src"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_van_image, {
        width: "5.6533rem",
        height: "5.6533rem",
        src: require('../assets/ip.png')
      }, null, 8, ["src"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_toButton, { btnAim: $data.medica }, null, 8, ["btnAim"]),
      _createVNode(_component_van_popup, {
        show: $data.show,
        "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (($data.show) = $event))
      }, {
        default: _withCtx(() => [
          ($data.flag)
            ? (_openBlock(), _createBlock(_component_medical, {
                key: 0,
                onOnChanged: $options.updateChanged,
                postaimqus: $data.postaimqus
              }, null, 8, ["onOnChanged", "postaimqus"]))
            : _createCommentVNode("", true),
          (!$data.flag)
            ? (_openBlock(), _createBlock(_component_medical, {
                key: 1,
                onOnChanged: $options.updateChanged,
                postaimqus: $data.postaimqus
              }, null, 8, ["onOnChanged", "postaimqus"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["show"])
    ])
  ], 64))
}