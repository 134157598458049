
import { ref, reactive } from "vue";
import { useRouter } from "vue-router";
export default {
  mode: "hash",
  components: {},
  props: {
    menu: {
      menu: String,
      default: 5,
      required: false,
    },
  },
  data() {
    var NavList = reactive([
      { id: 1, title: "病史线索", url: "/medica" },
      { id: 2, title: "病情诊断", url: "/Branch" },
      { id: 3, title: "病例治疗", url: "/case" },
      { id: 4, title: "诊疗结局", url: "/effect" },
      { id: 5, title: "病例启示录", url: "/end" },
    ]);

    return {
      show: false,
      postaimqus: 0,
      title: "传递的是一个值",
      flag: true,
      medica: "medica",
      navList: NavList,
      whichItem: "nav-item",
    };
  },
  setup() {
    //router是全局路由对象，route= userRoute()是当前路由对象
    let router = useRouter();
    const checked = ref("");
    let name = "张三";
    let age = "18";
    let btnAim = "/medica";
    // if (this.menu) return { checked, age, btnAim };
  },
  methods: {
    clickNav(e) {
      var domList = e.currentTarget.parentNode.children;
      for (let d = 0; d < domList.length; d++) {
        domList[d].className = "nav-item";
      }
      console.log("点击", e.currentTarget);
      e.currentTarget.className = "nav-item isclick";
    },
    saveAction() {
      console.log("saveAciton");
    },

    shareAction() {
      console.log("shareAction");
    },
    showPopup() {
      this.$router.push({
        path: "/branch",
        // query: { postaimqus: "1", correct: "two" },
      });
    },
  },
};
