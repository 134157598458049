import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2cdf3374"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav" }
const _hoisted_2 = { class: "aBox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.navList, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.id,
        class: _normalizeClass(item.id == $props.menu ? 'nav-item-two' : 'nav-item'),
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.clickNav($event)))
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_router_link, {
            to: item.url
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.title), 1)
            ]),
            _: 2
          }, 1032, ["to"])
        ])
      ], 2))
    }), 128))
  ]))
}