<template>
  <div class="clue" ref="imageTofile">
    <div class="logo">
      <van-image
        width="2.1333rem"
        height=".6667rem"
        :src="require('../assets/logo.png')"
      />
    </div>
    <div class="kuang">
      <div class="div1">恭喜你探案成功</div>
      <div class="div2">
        <div class="laba">
          <van-image
            width=".8rem"
            height=".8rem"
            :src="require('../assets/laba.jpg')"
          />
        </div>
        <div class="labaTxt">病例启示录</div>
      </div>

      <div class="innerBox">
        <div class="textBox">
          <span class="iconStart"></span>
          <span class="red">感染</span>是AD重要的诱发加重因素，
          <div class="trLine">需警惕金黄色葡萄球菌感染。</div>
        </div>

        <div class="textBox">
          <span class="iconStart"></span>
          <span class="red">金黄色葡萄球菌定植</span>可加重AD病情。
          <div class="trLine">需及时采取抗微生物治疗。</div>
        </div>
        <div class="textBox">
          <div class="iconStart"></div>
          AD继发感染时，
          <div class="trLineTwo">
            推荐联合使用外用抗生素<span class="red">1-2周。</span>
          </div>
        </div>
        <div class="textBox">
          <span class="iconStart"></span>
          共识推荐2%<span class="red">莫匹罗星软膏</span>用于AD继发感染的治疗
        </div>
        <ul>
          <li>耐药率低，敏感率高</li>
          <li>
            联合激素治疗儿童AD疗效优于单用激素，<br />且可缩短临床症状缓解时间
          </li>
          <li>不良反应率较低</li>
        </ul>
      </div>
    </div>

    <div class="home">
      <!-- <van-button style="width: 4rem; margin-top: 1rem">患儿基础信息</van-button> -->
      <!-- <van-button @click="showPopup" style="width: 4rem; margin-top: 1rem"
      >诊断</van-button
    > -->

      <div class="baocun">
        <a href="/static/img/endjpb.jpg" download="endjpb.jpg">
          <van-image
            width="2.5733rem"
            height="2.5733rem"
            :src="require('../assets/baocun.png')"
        /></a>
      </div>
      <van-popup v-model:show="show">
        <medical
          v-if="flag"
          @onChanged="updateChanged"
          :postaimqus="postaimqus"
        ></medical>

        <medical
          v-if="!flag"
          @onChanged="updateChanged"
          :postaimqus="postaimqus"
        ></medical>
      </van-popup>
    </div>
  </div>
  <div class="page-body">
    <toNav :menu="5"></toNav>
    <router-view />
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import medical from "@/views/MedicalView.vue";
import html2canvas from "html2canvas";
import ScreenShort from "js-web-screen-shot";
import toNav from "@/components/ToNav.vue";
export default {
  mode: "hash",
  components: { medical, toNav },
  data() {
    return {
      show: false,
      postaimqus: 0,
      title: "传递的是一个值",
      flag: true,
      medica: "medica",
    };
  },
  setup() {
    //router是全局路由对象，route= userRoute()是当前路由对象
    let router = useRouter();
    const checked = ref("");
    let name = "张三";
    let age = "18";
    let btnAim = "/medica";

    return { checked, age, btnAim };
  },
  methods: {
    jitT() {
      const screenShotHandler = new ScreenShort({
        enableWebRtc: true, //是否显示选项框
        level: 99, //层级级别
        completeCallback: this.callback,
        closeCallback: this.closeFn,
      });
    },

    saveAction() {
      console.log("saveAciton");
    },

    shareAction() {
      console.log("shareAction");
    },
    showPopup() {
      //this.show = true;
      // console.log("test it!" + this.$route.query["postaimqus"]);
      // console.log("test it!" + this.$route.query["correct"]);
      this.$router.push({
        path: "/branch",
        // query: { postaimqus: "1", correct: "two" },
      });
    },
    toImage() {
      // 手动创建一个 canvas 标签
      const canvas = document.createElement("html");
      // 获取父标签，意思是这个标签内的 DOM 元素生成图片
      // imageTofile是给截图范围内的父级元素自定义的ref名称
      let canvasBox = this.$refs.imageTofile;
      // 获取父级的宽高
      const width = parseInt(window.getComputedStyle(canvasBox).width);
      const height = parseInt(window.getComputedStyle(canvasBox).height);
      // 宽高 * 2 并放大 2 倍 是为了防止图片模糊
      canvas.width = width * 2;
      canvas.height = height * 2;
      canvas.style.width = width + "px";
      canvas.style.height = height + "px";
      const context = canvas.getContext("2d");
      context.scale(2, 2);
      const options = {
        backgroundColor: null,
        canvas: canvas,
        useCORS: true,
      };
      html2canvas(canvasBox, options).then((canvas) => {
        // toDataURL 图片格式转成 base64
        let dataURL = canvas.toDataURL("image/png");
        console.log(dataURL);
        this.downloadImage(dataURL);
      });
    },
    //下载图片
    downloadImage(url) {
      // 如果是在网页中可以直接创建一个 a 标签直接下载
      let a = document.createElement("a");
      a.href = url;
      a.download = "首页截图";
      a.click();
    },
    updateChanged(param) {
      this.aimQus = param[0];
      this.radioVal = param[1];
      switch (this.postaimqus) {
        case (this.postaimqus = 0):
          //这里是值对应的处理
          if (this.radioVal == 1) {
            this.postaimqus = 1;
            console.log("第一道题答对了，显示正确内容继续");
          } else {
            console.log("第一道题答错了，显示正确内容继续");
          }
          break;
        case (this.postaimqus = 1):
          //这里是值对应的处理
          if (this.radioVal == 4) {
            this.postaimqus = 2;
            console.log("第二道题答对了，显示正确内容继续");
          } else {
            console.log("第二道题答错了，显示正确内容继续");
          }
          break;
        case (this.postaimqus = 2):
          //这里是值对应的处理
          if (this.radioVal == 3) {
            this.postaimqus = 3;
            console.log("第三道题答对了，显示正确内容继续");
          } else {
            console.log("第三道题答错了，显示正确内容继续");
          }
          break;
        case (this.postaimqus = 3):
          //这里是值对应的处理
          if (this.radioVal == 1) {
            this.postaimqus = 4;
            console.log("第四道题答对了，显示正确内容继续");
          } else {
            console.log("第四道题答错了，显示正确内容继续");
          }
          break;
        case (this.postaimqus = 4):
          //这里是值对应的处理
          if (this.radioVal == 3) {
            console.log("第五道题答对了，显示正确内容继续");
          } else {
            console.log("第五道题答错了，显示正确内容继续");
          }
          break;

        default:
          //这里是没有找到对应的值处理
          break;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.kuang {
  width: 9.04rem;
  height: 13.6933rem;
  display: block;
  background-image: url(../assets/endbak.png);
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  // margin-top: 1.2667rem;
}
.fdj {
  margin-top: -3rem;
  height: 250px;
}
.div1,
.div2 {
  font-family: "sourcehansans";
  color: #00954f;
  font-weight: 900;
  display: block;
  font-size: 0.65rem;
}
.div2 {
  width: 5rem;
  margin: 0 auto;
}
.div1 {
  padding-top: 3.6333rem;
  text-align: left;
  text-indent: 0.8rem;
}
.labaTxt {
  width: 3rem;
  font-size: 0.45rem;
  float: left;
}
.laba {
  width: 0.8rem;
  height: 0.8rem;
  float: left;
}
.innerBox {
  width: 7rem;
  font-size: 0.3rem;
  text-align: left;
  padding-top: 0.9333rem;
  margin: 0 auto;
  color: #00954f;
}
.innerBox ul {
  color: #fa8c19;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
}
.innerBox ul li {
  color: #fa8c19;
  background-repeat: no-repeat;
  background-position: 0px 5px;
  list-style-type: disc;
}
.red {
  float: revert;
  color: #fa8c19;
}
.iconStart {
  width: 0.4rem;
  height: 0.4rem;
  float: left;
  background-image: url(../assets/start.jpg);
  background-size: cover;
}
.textBox {
  width: 7.8rem;
  height: 1.033rem;
}
.baocun {
  width: 6rem;
  display: block;
  margin: 0 auto;
}
.bar {
  height: 10rem;
  color: #00954f;
}
.trLine {
  width: 5rem;
  padding-left: 0.4rem;
}
.trLineTwo {
  width: 5rem;
  padding-left: 0.4rem;
  float: left;
}
</style>
