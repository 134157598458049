
// 引入swiper的js
import Swiper from "swiper/swiper-bundle.js";
import "swiper/swiper-bundle.min.css";

export default {
  name: "ToButton",
  props: {
    btnAim: {
      type: String, //name的类型是字符串
      required: true, //name是必传项
    },
  },
};
