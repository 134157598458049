import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import QuestionView from "../views/QuestionView.vue";
import MedicalView from "../views/MedicalView.vue";
import CorrectView from "../views/CorrectView.vue";
import ReasonView from "../views/ReasonView.vue";
import ClueView from "../views/ClueView.vue";
import ClueGogoView from "../views/ClueGogoView.vue";
import ClueGoView from "../views/ClueGoView.vue";
import BranchView from "../views/BranchView.vue";
import EffectView from "../views/EffectView.vue";
import ReportView from "../views/ReportView.vue";
import SummaryView from "../views/SummaryView.vue";
import CaseView from "../views/CaseView.vue";
import EndView from "../views/EndView.vue";
import DiagView from "../views/DiagView.vue";
/*ReportView,SummaryView,CaseView EndView DiagView*/

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: QuestionView,
  },

  {
    path: "/medica",
    name: "medica",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: MedicalView,
  },

  {
    path: "/correct",
    name: "correct",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: CorrectView,
  },
  {
    path: "/reason",
    name: "reason",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.ClueGogoView.vue
    component: ReasonView,
  },
  {
    path: "/cluego",
    name: "cluego",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ClueGoView,
  },
  {
    path: "/cluegogo",
    name: "cluegogo",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ClueGogoView,
  },
  {
    path: "/clue",
    name: "clue",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ClueView,
  },
  {
    path: "/branch",
    name: "branch",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: BranchView,
  },
  {
    path: "/effect",
    name: "effect",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: EffectView,
  },
  {
    path: "/end",
    name: "end",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: EndView,
  },

  {
    path: "/report",
    name: "report",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ReportView,
  },
  {
    path: "/summary",
    name: "summary",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: SummaryView,
  },
  {
    path: "/case",
    name: "case",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: CaseView,
  },
  {
    path: "/diag",
    name: "diag",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: DiagView,
  },
];
/*branch,EffectView,SummaryView,CaseView,DiagView.vue*/

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
