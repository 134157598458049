
import { ref } from "vue";
import { useRouter } from "vue-router";
import question from "@/views/QuestionView.vue";
import correctTwo from "@/views/CorrectTwoView.vue";
import { showDialog } from "vant";
// import toButton from "@/components/ToButton.vue";
export default {
  components: { question, correctTwo },
  data() {
    return {
      show: false,
      postaimqus: 3,
      title: "传递的是一个值",
      flag: "question",
      medica: "medica",
      radioVal: 0,
      correct: 1,
      num: 0,
    };
  },
  setup() {
    //router是全局路由对象，route= userRoute()是当前路由对象
    let router = useRouter();
    const checked = ref("");
    let name = "张三";
    let age = "18";
    let btnAim = "/medica";

    return { checked, age, btnAim };
  },
  methods: {
    showPopup() {
      this.$router.push({
        name: "case",
      });

      // console.log("test it!" + this.$route.query["postaimqus"]);
      // console.log("test it!" + this.$route.query["correct"]);
      // this.$router.push({
      //   path: "/branch",
      //   // query: { postaimqus: "1", correct: "two" },
      // });
    },
    updateCorrect(param) {
      this.postaimqus = 4;
      this.num = this.num + 1;
      console.log("num=" + this.num);
      this.flag = "question";
    },
    updateChanged(param) {
      console.log("sdfsdfsdfsdfd");
      console.log(param[0]); //获取param1的值
      console.log(param[1]); //获取param2的值
      this.postaimqus = param[0];
      this.radioVal = param[1];

      if (this.postaimqus == 3) {
        if (this.radioVal == 0) {
          showDialog({
            title: "提示",
            message: "您还没有做出选择哟。",
          }).then(() => {
            // on close
          });
        } else {
          if (this.radioVal == 4) {
            this.flag = "correct";
            this.correct = 1;
            this.postaimqus = 3;
            console.log("sdfsdfsdfsdfsdfsdfsdf" + this.radioVal);
            console.log("第一道题答对了，显示正确内容继续");
          } else {
            this.postaimqus = 3;
            console.log("第一道题答错了，显示正确内容继续");
            this.flag = "correct";
            this.correct = 0;
          }
        }
      }
    },
  },
};
