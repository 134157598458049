
import { ref } from "vue";
import { useRouter } from "vue-router";

import correctTwo from "@/views/CorrectTwoView.vue";
import question from "@/views/QuestionView.vue";
import toNav from "@/components/ToNav.vue";
import { showDialog } from "vant";
// import toButton from "@/components/ToButton.vue";
export default {
  components: { correctTwo, question, toNav },
  data() {
    return {
      show: false,
      postaimqus: 1,
      title: "传递的是一个值",
      flag: "question",
      medica: "medica",
      correct: "0",
    };
  },
  setup() {
    //router是全局路由对象，route= userRoute()是当前路由对象
    let router = useRouter();
    const checked = ref("");
    let name = "张三";
    let age = "18";
    let btnAim = "/medica";

    return { checked, age, btnAim };
  },
  methods: {
    updateCorrect(param) {
      this.$router.push({
        path: "/cluego",
        // query: { postaimqus: "1", correct: "two" },
      });
      // this.postaimqus = param[0];
      // console.log("shiwo");
      // this.flag = "reason";
      // console.log("赋值reason");
      // if (this.postaimqus == "1") {
      //   this.flag = "reason";
      // } else if (this.postaimqus == "2") {
      //   //此处用来判断
      //   this.flag = "question";
      //   this.postaimqus = 2;
      // }
    },
    reasonCorrect(param) {
      console.log("reasonCorrect");
      this.flag = "question";
      this.postaimqus = 1;
    },
    showPopup() {
      //flag == 'question
      this.postaimqus = "3";
      this.flag = "question";
      this.show = true;
      // console.log(this.show + "test it!");

      this.$router.push({
        path: "/branch",
        // query: { postaimqus: "1", correct: "two" },
      });
    },
    updateChanged(param) {
      //获取param1的值

      console.log(param[1]); //获取param2的值
      this.postaimqus = param[0];
      this.radioVal = param[1];
      switch (this.postaimqus) {
        case (this.postaimqus = 0):
          //这里是值对应的处理

          if (this.radioVal == 1) {
            this.postaimqus = 1;
            console.log("第一道题答对了，显示正确内容继续");
          } else {
            console.log("第一道题答错了，显示正确内容继续");
          }
          break;
        case (this.postaimqus = "3"): //第二道题判断
          //这里是值对应的处理
          if (this.radioVal == 0) {
            showDialog({
              title: "提示",
              message: "您还没有做出选择哟。",
            }).then(() => {
              // on close
            });
          } else {
            if (this.radioVal == 4) {
              this.flag = "correct";
              this.correct = "1";
              console.log("第一道题答对了，显示正确内容继续");
            } else {
              console.log("第一道题答错了，显示正确内容继续");
              this.flag = "correct";
              this.correct = "0";
            }
          }
          break;

        default:
          //这里是没有找到对应的值处理
          break;
      }
    },
  },
};
