import { createStore } from "vuex";

export default createStore({
  state: {
    questions: [
      {
        num: "one",
        bigTitle: "患儿AD加重，且渗出严重，最可能为哪种加重诱发因素？",
        radioList: [
          {
            name: "1",
            title: "A.细菌感染",
          },
          {
            name: "2",
            title: "B.吸入变应原，如花粉",
          },
          {
            name: "3",
            title: "C.食物变应原，如牛奶",
          },
          {
            name: "4",
            title: "D.接触变应原，如汗液",
          },
          {
            name: "5",
            title: "E.情绪原因，如压力、焦虑",
          },
        ],
      },
      {
        num: "two",
        bigTitle:
          "AD继发细菌感染且有明显感染征象时，以下哪个治疗方案较为合适？",
        radioList: [
          {
            name: "1",
            title: "A.渗出部位用抗炎制剂，并配合保湿剂治疗",
          },
          {
            name: "2",
            title: "B. 渗出部位仅用抗生素治疗",
          },
          {
            name: "3",
            title: "C.渗出部位抗炎制剂联合抗生素治疗",
          },
        ],
      },
      {
        num: "four",
        bigTitle:
          "AD是一种异质性疾病，表现多种多样，诊断需要一定标准，姚志荣等提出的中国儿童AD临床诊断标准,敏感率高，以下对该标准描述正确的为（）",
        radioList: [
          {
            name: "1",
            title: "A. 瘙痒",
          },
          {
            name: "2",
            title:
              "B.典型的形态和部位（屈侧皮炎）或不典型的形态和部位同时伴发干皮症",
          },
          {
            name: "3",
            title: "C.慢性或慢性复发性病程",
          },
          {
            name: "4",
            title: "D.同时具备以上3条即可诊断AD",
          },
          {
            name: "5",
            title: "E.符合A、B、C任意2条即可诊断AD",
          },
        ],
      },
      {
        num: "three",
        bigTitle: "通过该患儿的体格检查，以下描述正确的有()",
        radioList: [
          {
            name: "1",
            title: "A. 四肢伸侧、腰腹部红斑丘疹，水疱，大量渗出，可见黄痂",
          },
          {
            name: "2",
            title: "B. 局部可见抓痕",
          },
          {
            name: "3",
            title: "C. 躯干红斑，无明显丘疹、水疱",
          },
          {
            name: "4",
            title: "D. 以上均描述正确",
          },
        ],
      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
